<template>
    <div class="home">
        <x-header v-if="this.status == '0'" back>待复核</x-header>
        <x-header v-else back>历史复核</x-header>
        <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
            <bounce-loading></bounce-loading>
        </x-content>
        <x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
            <!-- <div class="search_warp">
                <el-row class="list-control_warp">
                    <el-col :span="24">
                        <el-col :span="6">
                            <el-input alwaysShowClear clearable v-model="searchText" @keydown.enter.native="search" placeholder="输入实验名称">
                                <template slot="suffix">
                                    <i @click="search" style="cursor: pointer;" class="el-input__icon base-icon-search"></i>
                                </template>
                            </el-input>
                        </el-col>
                        &nbsp;
                        <div style="display: flex; justify-content: flex-end;">
                            <el-button style="" size="medium" type="primary" @click="openDialog" icon="base-icon-plus">
                                    <span>新建实验</span>
                            </el-button>
                        </div>
                    </el-col>
                </el-row>
            </div> -->
            <div v-if="status=='0'&&total>0" style="margin-bottom:10px;">
                <el-button icon="el-icon-success" @click="reviewSuccess"> 复核通过</el-button>
                <el-button icon="el-icon-error" @click="reviewRefuse"> 复核拒绝</el-button>
            </div>
            <x-card>
                <el-table
                    :data="experimentList"
                    stripe
                    ref="reviewExperiemnt"
                    @selection-change="handleSelectionChange"
                    style="width: 100%">
                    <el-table-column
                        v-if="status=='0'"
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column
                        prop="experiment_id"
                        label="实验编号">
                        <template slot-scope="scope">
                            <el-tooltip content="查看实验详情" placement="bottom">
                            <el-link style='color:#409EFF' @click="$router.push('/manager/experiment/detail/'+scope.row.uuid+'/1')" :underline="false">{{scope.row.experiment_id}}</el-link>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="subject"
                        label="实验名称">
                    </el-table-column>
                    <el-table-column
                        label="所属项目">
                        <template slot-scope="scope">
                               <span class="">{{experimentGroupIdNameMap[scope.row.group_id]}}</span> 
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="250"
                        label="属性">
                        <template slot-scope="scope">
                            <div style="display: flex; flex-direction: column;">
                                <span class="">创建时间：{{scope.row.create_time}}</span>
                                <span class="" v-if="scope.row.update_time">修改时间：{{scope.row.update_time}}</span>
                                <span class="">创建人：{{scope.row.user.name}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="100"
                        label="实验状态">
                        <template slot-scope="scope">
                            <span class="group_operate">{{scope.row.status=='0'?"待复核":scope.row.status=='1'?'复核通过':scope.row.status=='2'?'复核拒绝':scope.row.status=='3'?'待提交':scope.row.status=='4'?'已归档':''}}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
                        width="200"
                        label="操作">
                        <template slot-scope="scope">
							<el-button  class="group_operate" size="mini" @click="$router.push('/manager/experiment/edit/'+scope.row.note.id+'/'+scope.row.form_template_id+'/'+scope.row.uuid+'/0')" type="success">编辑</el-button>
                            <el-button  class="group_operate" size="mini" @click="deleteExperiment(scope.row)" type="warning">删除</el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
                <div class="page-warp">
                    <el-pagination
                        @current-change="listExperiment"
                        :hide-on-single-page="total<pageSize"
                        background
                        layout="total,prev, pager, next"
                        :total="total"
                        :page-size="pageSize"
                        :current-page.sync="page"
                    ></el-pagination>
                </div>
            </x-card>
        </x-content>
      <app-dialog  ref="userSignatureDialog" dialogTitle="验证签名密码" originTitle staticDialog width="600px">
        <div>
          <el-form >
            <el-form-item  v-model="password">
              <el-input show-password v-model="password" placeholder="请输入签名密码"></el-input>
            </el-form-item>
            <div class="save_btn_warp">
              <el-button type="primary" @click="verify" style="width:200px;" size="medium" :loading="saveLoading">确定</el-button>
            </div>
          </el-form>
        </div>
      </app-dialog>
      <app-dialog  ref="scoreDialog" dialogTitle="实验复核" originTitle staticDialog width="600px">
        <el-form >
        <el-form-item >
        <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入复核意见"
            v-model="reason">
        </el-input>
        </el-form-item>
        <template v-if="this.reviewStatus !== '2'">
          <el-form-item  label="评分">
          <el-rate style="line-height: 50px !important;"
              v-model="reviewScore"
              show-text>
          </el-rate>
          </el-form-item>
        </template>
        </el-form>
        <div class="save_btn_warp">
          <el-button type="primary" style="width:200px;" size="medium"  @click="reviewSignature">确定</el-button>
        </div>
      </app-dialog>
        <app-dialog ref="addDialog" dialogTitle="复核签名" originTitle staticDialog width="600px">
          <el-form :model="userSignature" ref="userSignatureForm" >
            <div style="display: flex;justify-content: center;">
              <vue-esign
                  ref="esign"
                  class="mySign"
                  :width="600"
                  :height="300"
                  :isCrop="false"
                  :lineWidth="6"
                  lineColor="#000000"
                  bgColor.sync="bgColor"
              />
            </div>
            <el-form-item >
              <el-input v-model="userSignature.password" show-password @clear="userSignature.password = ''" placeholder="请输入签名密码，密码格式为6位数字，下次输入密码即可完成电子签名"></el-input>
            </el-form-item>
          </el-form>
          <div class="save_btn_warp">
            <el-button type="warning" style="width:20%;" size="medium" @click="resetSign">清空签名</el-button>
            <el-button type="primary" style="width:20%;" size="medium" @click="saveSignImg">确定</el-button>
          </div>
        </app-dialog>
    </div>
</template>
<script>
import {GetMyProjectList, GetUserDetails} from "@/service/user"
    // import {GetByUserIdNote,DetailNote} from "@/service/note";
    // import {GetByUserIdFormTemplate} from "@/service/formTemplate";
    import {GetReviewList,ReviewExperiment} from "@/service/experiment";
    import {GetSignature, SignatureSave, Verify} from "@/service/userSignature";
    let codeData = [];
    export default {
        name:'ExperimentReview',
        data(){
            return {
                loading:false,
                saveLoading:false,
                savePermissionLoading:false,
                page:1,
                total:0,
                pageSize:10,
                searchText:"",
                experimentList:[],
                status:"",
                selectReviewExperiment:[],
                experimentGroupIdNameMap:{},
                reviewStatus:"",
                reviewScore:null,
                reason:'',
                password:'',
                userSignature:{},
                currentUser:{},
                review_users:[],
            }
        },
	    	created(){
            this.status = this.$route.params.status || '';
            this.listExperiment();
            this.getMyProjectList();
            this.getUser()

        },
        watch:{
			'$route.params.status':{
				handler:function (newVal){
                    if(newVal){
                        this.status = newVal
                        this.listExperiment();
                    }
				},
				immediate:true
			}
		},
        methods:{
            search() {
			           	this.page = 1;
				          this.listExperiment();
            },
            getMyProjectList(){
                GetMyProjectList().then(e => {
                    let data = this.APIParse(e);
                    data.forEach(item => {
                      this.$set(this.experimentGroupIdNameMap,item.id,item.groupName)
                    })
                })
            },
            openDialog(){
                this.form.topicHead = this.user.name
                this.$refs['addDialog'].show()
            },
            goExperimentEdit(){
                this.$router.push('/manager/experiment/edit/'+this.form.note+'/'+this.form.formTemplate+'/1/0')
            },
            getUser() {
              GetUserDetails().then(e => {
                this.currentUser = e.data;
              }).catch(e => {
                this.$message.error(e.message)
              }).finally(e => {

              })
            },
            listExperiment(){
                this.loading = true;
                GetReviewList(this.status,this.page,this.pageSize).then(e => {
                    let data = this.APIParse(e);
                    this.experimentList = data?.hits.map(item => {
                        return item._source;
                    });
                    this.total = data.total || 0;
                }).catch(e => {
                    this.$message.error(e.message)
                }).finally(e => {
                    this.loading = false;
                })
            },
            handleSelectionChange(val){
                this.selectReviewExperiment = val;
            },
            verify(){
              Verify(this.password).then(e => {
                let data = this.APIParse(e);
                if (data) {
                  //this.$message.success("校验成功")
                  this.$refs['userSignatureDialog'].hide()
                  this.$refs['scoreDialog'].hide()
                  this.password=''
                  this.reviewExperimentSuccess()
                }else{
                  this.$message.error("签名密码输入错误，请检查")
                  this.password='';
                }
              })
            },
            reviewSignature(){
              if(!this.reason){
                this.$message.warning("请输入复核意见！")
                return
              }
              GetSignature().then(e => {
                    if (e.data) {
                      this.userSignature = e.data;
                      this.signImg =  this.userSignature.signature;
                      this.$refs['userSignatureDialog'].show()
                    } else {
                      this.$refs['addDialog'].show()
                    }
                  }
              ).catch(e => {
                this.$message.error(e.message)
              }).finally(e => {
              })
            },
            reviewSuccess(){
                if(this.selectReviewExperiment.length==0){
                    this.$message.warning("请选择实验！")
                    return
                }
              this.reviewStatus = "1"
              this.$refs['scoreDialog'].show();
            },

            reviewRefuse(){
                if(this.selectReviewExperiment.length==0){
                    this.$message.warning("请选择实验！")
                    return
                }
              this.reviewStatus = "2"
              this.$refs['scoreDialog'].show();
            },
            resetSign(){
                this.$refs.reviewEsign.reset();
            },
            saveSignImg(){
             if(!this.userSignature.password){
              this.$message({
                type: "warning",
                message: "请输入签名密码",
              });
              return
             }
              var patten =/^\d{6}$/;
              if (!patten.test(this.userSignature.password)) {
                this.$message("请输入正确的密码格式");
                return false;
              }
             this.$refs.esign
                .generate() // 使用生成器调用把签字的图片转换成为base64图片格式
                .then((res) => {
                  // 构造 FormData，并将签名图片对象和签名密码作为字段传入
                  this.signImg = res;
                  // this.$refs['addDialog'].hide();
                  // this.param.sign_img = res;a
                  this.userSignature.signature=res
                  if(!this.userSignature.signature){
                    this.$message({
                      type: "warning",
                      message: "您还没有签名",
                    });
                    return
                  }
                  SignatureSave(this.userSignature).then(e => {
                    if(e.code ==-1){
                      this.$message.error(e.msg)
                      return;
                    }
                    let data = this.APIParse(e)
                    this.reviewExperimentSuccess()
                    this.hideReview()
                  })
                })
                .catch((err) => {
                  // 画布没有签字时会执行这里提示一下
                  this.$message({
                    type: "warning",
                    message: "您还没有签名",
                  });
                });

             },
             reviewExperimentSuccess(){
               let param = []
               const updatedReviewUsers = [];
               for (const index in this.selectReviewExperiment) {
                 let item = this.selectReviewExperiment[index]
                 this.review_users=item.review_user
                 if (Array.isArray(this.review_users)){
                   this.review_users.forEach(item => {
                     const updatedItem = {...item};
                     if (updatedItem.id === this.currentUser.id) {
                       updatedItem.sign_img = this.userSignature.signature; // 添加 sign_img 属性
                     }
                     updatedReviewUsers.push(updatedItem);
                   });
                   this.review_users = updatedReviewUsers;
                 }else{
                   this.review_users.sign_img=this.userSignature.signature;
                 }
                 param.push({
                   uuid : item.uuid,
                   status :this.reviewStatus,
                   reason:this.reason,
                   review_score:this.reviewScore,
                   group_id : item.group_id,
                   user: item.user,
                   review_user :this.review_users
                 })
               }
               this.$message.info("实验复核上链中，请耐心等待")
              this.loading = true;
               ReviewExperiment(param).then(e => {
                this.loading = false;
                 if(e.code ==-1){
                   this.$message.error(e.msg)
                   return;
                 }
                 let data = this.APIParse(e)
                 this.$message.success("复核成功")
                 this.listExperiment()
                 this.hideReview()
                 this.reason='';
                 this.reviewScore='';
                 // this.$router.push('/manager/experiment/review/1');
               })
             },
            hideReview(){
                this.$refs['scoreDialog'].hide();
                this.$refs['userSignatureDialog'].hide()
                this.$refs['addDialog'].hide();
                this.$refs.reviewExperiemnt.clearSelection();
                //this.$refs.reviewEsign.reset();
            },
            changeShow(){
                this.page = 1
                this.listExperiment();
            },
        }
    }
</script>
<style lang="scss" scoped>
    .group_operate+.group_operate{
        margin-left: 15px;
    }
    .Width-100{width: 100%}
    .home{width: 100%;height: 100%;overflow: auto;}
    .search_warp{width:100%;margin: 16px 0px;}
    
    .save_btn_warp{width: 100%;margin-top: 32px;display: flex;align-items: center;justify-content: center;}
    .page-warp{
        text-align: center;
        margin-top: 20px;
    }
    .mySign {
        border: 1px dashed #000;
    }
</style>